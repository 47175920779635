<template>
    <section>
        <section class="d-flex flex-wrap poppins align-center">
            <v-btn text dense 
                @click="(prevRoute && prevRoute.name === 'Admin Course Enrollment') ? $router.back() : $router.push({ name: 'Admin Course Enrollment' , query: { search: '', page: 1, paginate: 10, role: '', status: '', created_at: '' }})"  class="mr-1 pa-0 f14">
                <v-icon small>
                    mdi-chevron-left
                </v-icon>
                BACK
            </v-btn>
            <span class="f14">/</span> <span class="fw600 primary--text f14 ml-2">{{ title  }}</span>
        </section>

        <section class="d-flex flex-wrap justify-space-between">
            <SearchBar 
                :placeholder="'Search user'" 
                :value.sync="search" 
                @clear="() => { search = '', page = 1, getEnrollees() }" 
                @search="() => { page = 1, getEnrollees()}" 
                class="mb-5" />
                
            <ButtonExport 
                @click="exportEnrollees"
                :loading="exportLoading"
                :disabled="loading || enrollees.length === 0"
                class="ma-1 ml-auto"/>
        </section>
        
        <section class="text-right">
            <FormLabel :label="`${totalCount} result/s`"/>
        </section>
        <v-data-table
            :loading="loading"
			:search="search"
            :headers="courseEnrollees"
            :items="enrollees"
            class="text--center custom-border poppins f14 mt-2"
            :footer-props="{
                'items-per-page-options': itemsPerPageOptions
            }"
            :page="page"
            :server-items-length="totalCount"
            @pagination="(e) => {
                page = e.page
                paginate = String(e.itemsPerPage),
                getEnrollees()
            }">
            <template v-slot:item.date_enrolled="{ item }" >
                {{ $dateFormat.dayDateTime(item.date_enrolled) }}
            </template>
            <template v-slot:item.date_approved="{ item }" >
                {{ $dateFormat.dayDateTime(item.date_approved) }}
            </template>
            <template v-slot:item.official_receipt="{ item }" >
                <!-- <v-btn outlined color="secondary-1" dense class="pa-2 f12" @click="requestOR(item)"> Request OR </v-btn> -->
                <v-btn outlined color="secondary-1" dense class="pa-2 f12" :href="item?.official_receipt?.url" target="_blank"> View OR </v-btn>
            </template>
            <template v-slot:item.action="{ item }">
                <v-select
                    outlined
                    :items="actionItems"
                    item-text="text"
                    item-value="value"
                    width="100"
                    class="no-outline-custom-field poppins f14 ma-auto"
                    dense
                    v-model="item.invoice_status"
                    v-on:change="updateStatus(item)"
                    :style="'width: min-content;'"
                    hide-details
                >
                    <template slot="selection"  slot-scope="data">
                        <span dense class="secondary-2--text px-2 border" style="background-color: #BDBDBD33">PENDING</span>
                    </template>
                </v-select>
            </template>
        </v-data-table>
        <v-dialog
            persistent
            v-model="confirmationDialog"
            max-width="400">
            <v-card>
                <section class="d-flex flex-row">
                    <v-spacer />
                    <v-btn icon @click="confirmationDialog = false, item.invoice_status = 2" class="ml-auto">
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </section>
                
                <section class="d-flex align-center flex-column text-center px-5 py-10">
                    <div class="primary--text fw600 f20">
                        Approve user ?
                    </div>
                    <div class="secondary--text roboto f14 mx-auto">
                        This action cannot be undone.
                    </div>
                </section>
                <v-divider></v-divider>
                <section class="secondary--text poppins d-flex flex-row pa-3">
                    <v-btn text @click="() => {
                            confirmationDialog = false, item.invoice_status = 2
                        }">
                        Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success poppins" @click="approve">
                        Approve
                    </v-btn>
                </section>
                
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import { api } from '@/services/axios'
import { mapActions, mapState, mapMutations } from 'vuex';
import { courseEnrollees } from '@/constants/tblheaders/enrollment';
export default {
    data: () => ({
        tab: 0,
        search: '',
        loading: false,
        exportLoading: false,
        courseEnrollees,
        confirmationDialog: false,
        items: [ 'PENDING', 'APPROVED', 'DISQUALIFIED'],
        actionItems: [
            { text: 'APPROVED', value: 1 },
            { text: 'PENDING', value: 2 },
            { text: 'DISQUALIFIED', value: 3 },
        ],
        item: null,
        toApprove: false,
        pageCount: 1,
        page: 1,
        paginate: '10',
        totalCount: 0,
        itemsPerPageOptions: [5, 10, 20, 30, 40, 50],
        title: '',
    }),
    methods: {
        ...mapActions('admin', ['getCourseEnrolleesAction', 'updateInvoiceStatus', 'courseEnrolleesExportAction']),
        ...mapMutations(['alertMutation']),

        approve() {
            this.confirmationDialog = false
            this.item.invoice_status = 2
            this.toApprove = true
            this.updateStatus(this.item)
        },

        updateStatus(item){
            this.item = item
            if(item.invoice_status === 1) {
                this.confirmationDialog = true
            } else {
                if(this.toApprove) {
                    this.updateInvoiceStatus({invoice_id: item.invoice_id, status: 1}).then(() => {
                        this.loading = true
                        this.getCourseEnrolleesAction({course_uuid: this.$route.params.course_uuid, invoice_status: this.tab === 0 ? 2 : this.tab === 1 ? 1 : 3 }).then(() => {
                            this.loading = false
                        })
                    })
                } else {
                    this.updateInvoiceStatus({invoice_id: item.invoice_id, status: item.invoice_status}).then(() => {
                        this.loading = true
                        this.getCourseEnrolleesAction({course_uuid: this.$route.params.course_uuid, invoice_status: this.tab === 0 ? 2 : this.tab === 1 ? 1 : 3 }).then(() => {
                            this.loading = false
                        })
                    })
                }
            }
        },

        async requestOR(item) {
            return await new Promise((resolve, reject) => {
                api.post(`/admin/request/oras/official-receipt`, { course_uuid: this.$route.params.course_uuid, user_id: item.user_id } ).then(res => {
                    this.alertMutation({
                        show: true,
                        text: "Requested OR successfully generated!",
                        type: "success"
                    })
                    var newDoc = document.implementation.createHTMLDocument();
                    var parsedContent = document.createElement('div');

                    var parser = new DOMParser();
                    var xmlDoc = parser.parseFromString(res.data, 'text/html');

                    parsedContent.appendChild(xmlDoc.documentElement);
                    newDoc.body.appendChild(parsedContent);
                    
                    var newTab = window.open();
                    newTab.document.write(newDoc.documentElement.outerHTML);

                }).catch(err => {
                    this.alertMutation({
                        show: true,
                        text: err.response.data.message,
                        type: "error"
                    })
                })
            })
        },

        getEnrollees(){
            if(!this.loading) {
                this.loading = true
                this.getCourseEnrolleesAction({course_uuid: this.$route.params.course_uuid, page: this.page, paginate: Number(this.paginate), search: this.search }).then(res => {
                    this.loading = false
                    this.loading=false
                    this.title = res.title
                    this.pageCount = res.users.last_page
                    this.totalCount = res.users.total
                    this.page = res.users.current_page
                }).catch(() => {
                    this.loading = false
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })
                })
            }
        },
        

        exportEnrollees(){
            this.exportLoading = true
            this.courseEnrolleesExportAction({
                course_uuid: this.$route.params.course_uuid,
                search: this.search,
                ...this.filter
            }).then((res) => {
                this.$exportToCSV(`${this.title} - Enrollees`, res)
            }).catch(e => {
                this.alertMutation({
                    show: true,
                    text: "Something went wrong",
                    type: "error"
                })
            }).finally(() => {
                this.exportLoading = false
            })
        }
    },
    computed: {
        ...mapState('admin', {
            enrollees: (state) => state.enrollees
        })
    },
    mounted() {
    },
    // watch: {
    //     tab(val) {
    //         this.$router.replace({ query: { type: val , timestamp: Date.now() } });
    //         this.loading = true

    //         this.getCourseEnrolleesAction({course_uuid: this.$route.params.course_uuid, invoice_status: val === 0 ? 2 : val === 1 ? 1 : 3}).then(() => {
    //             this.loading = false
    //         })
    //     }
    // }
}
</script>
